import { yupResolver } from "@hookform/resolvers/yup";
import FeatherIcon from "feather-icons-react";
import { isNil } from "lodash";
import { useMedusa } from "medusa-react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { LoadingContext } from "../..";
import { Button, Input, Link } from "@nextui-org/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { ForgotPasswordDialog } from "./components/forgot-password-dialog";
import { ResetPasswordDialog } from "./components/reset-password-dialog";
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from "../../utils";
import { toast } from "react-toastify";
import { ResendVerificationEmailDialog } from "./components/resend-verification-email-dialog";
import { useLogoutDeleteSession } from "../../lookups/customer-trades";

export type LoginFormType = {
  email: string;
  password: string;
};

const LoginPage = () => {
  const schema = yup
    .object({
      email: yup.string().email().required(),
      password: yup.string().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormType>({
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  const { client: medusaClient } = useMedusa();
  const [searchParams, setSearchParams] = useSearchParams();
  const passwordResetToken = searchParams.get("resetToken");
  const passwordResetEmail = searchParams.get("email");

  const [state, setState] = useState({
    loginSuccessful: false,
    obscurePass: true,
    isForgotPsswordDialogOpen: false,
    isResendVerificationEmailDialogOpen: false,
    isResetPasswordDialogOpen:
      !isNil(passwordResetToken) && !isNil(passwordResetEmail),
  });

  const { setLoading } = useContext(LoadingContext);
  const navigate = useNavigate();
  const{ logout} = useLogoutDeleteSession();

  const validateSignIn = async (data: LoginFormType) => {
    setState({ ...state });
    setLoading!(true);
    console.error("eggman");
    medusaClient.auth
      .getToken(data)
      .then(async (res) => {
        const {customer} = await medusaClient.customers.retrieve({
          Authorization: `Bearer ${res.access_token}`,
        });

        if (!(customer as any).is_verified) {// logout
          await logout();
          localStorage.clear();
          toast.error(
            // err?.message ?? err?.response?.data?.message ??
             "verify your account to login"
          );
          return;
        }

        if ((customer as any).is_frozen) {// logout
          await logout();
          localStorage.clear();
          toast.error(
            // err?.message ?? err?.response?.data?.message ??
             "your account has been frozen, contact support"
          );
          return;
        }

        localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, res.access_token);

        setState({ ...state, loginSuccessful: true });
        setLoading!(false);
      })
      .catch((err) => {
        setState({ ...state, loginSuccessful: false });
        toast.error(
          // err?.message ?? err?.response?.data?.message ??
           "Invalid Credentials"
        );
        setLoading!(false);
      });
  };

  return (
    <>
      <section className="w-full bg-white dark:bg-darkblack-500">
        <div className="flex flex-col lg:flex-row justify-between min-h-screen">
          {/* <!-- Left --> */}
          <div className="px-5 xl:pl-12 pt-16 m-auto">
            <header className="flex justify-center flex-row">
              {/* <!--              page-title--> */}
              <a href="https://autrid.com">
                <div className="bg-white rounded-md">
                  <img src="/autrid.png" className="w-[136px] px-4 py-2" />
                </div>
              </a>
            </header>
            <div className="max-w-[450px] m-auto pt-16 pb-16">
              <header className="text-center mb-8">
                <h2 className="text-bgray-900  text-4xl font-semibold font-poppins mb-2">
                  Sign in to Autrid
                </h2>
                <p className="font-urbanis text-base font-medium text-bgray-600 ">
                  Trade and Grow
                </p>
              </header>
              <form onSubmit={handleSubmit(validateSignIn)}>
                <div className="mb-4">
                  <Input
                    type="email"
                    label="Email"
                    placeholder="Enter your email"
                    {...register("email", { required: true })}
                    errorMessage={errors.email?.message}
                    isInvalid={!isNil(errors.email?.message)}
                    color={errors.email?.message ? "danger" : undefined}
                  />
                </div>

                <div className="mb-6">
                  <Input
                    label="Password"
                    fullWidth={true}
                    placeholder="Enter your password"
                    errorMessage={errors.password?.message}
                    isInvalid={!isNil(errors.password?.message)}
                    color={errors.password?.message ? "danger" : undefined}
                    endContent={
                      <button
                        className="focus:outline-none"
                        type="button"
                        onClick={() =>
                          setState({
                            ...state,
                            obscurePass: !state.obscurePass,
                          })
                        }
                      >
                        {state.obscurePass ? (
                          <FeatherIcon icon="eye-off"></FeatherIcon>
                        ) : (
                          <FeatherIcon icon="eye"></FeatherIcon>
                        )}
                      </button>
                    }
                    type={state.obscurePass ? "password" : "text"}
                    {...register("password", { required: true, minLength: 4 })}
                  />
                </div>

                <div className="flex justify-between mb-7">
                  <div className="flex items-center space-x-3"></div>
                  <div>
                    <Link
                      href="#"
                      className="text-sm"
                      onClick={() => {
                        setState({ ...state, isForgotPsswordDialogOpen: true });
                      }}
                    >
                      Forgot Password?
                    </Link>
                  </div>
                </div>

                <Button type="submit" fullWidth={true} color="warning">
                  Sign In
                </Button>

                <div className="mb-7">
                  <div>
                    <p className="text-bgray-600  text-center text-sm mt-6">
                      Don't have an account?&nbsp;&nbsp;&nbsp;
                      <Link
                        href="#"
                        className="text-sm"
                        onClick={() => navigate("/register")}
                      >
                        Register
                      </Link>
                    </p>
                    <p className="text-bgray-600  text-center text-sm mt-6">
                      <Link
                        href="#"
                        className="text-sm"
                        onClick={() => {
                          setState({
                            ...state,
                            isResendVerificationEmailDialogOpen: true,
                          });
                        }}
                      >
                        Resend Verifcation Email
                      </Link>
                    </p>
                  </div>
                </div>
              </form>
              <p className="text-bgray-600  text-center text-sm mt-6">
                @ 2024 Autrid Trading. All Right Reserved.
              </p>
            </div>
          </div>
        </div>
      </section>

      <ForgotPasswordDialog
        isOpen={state.isForgotPsswordDialogOpen}
        closeModal={() => {
          setState({
            ...state,
            isForgotPsswordDialogOpen: false,
          });
        }}
      ></ForgotPasswordDialog>

      <ResendVerificationEmailDialog
        isOpen={state.isResendVerificationEmailDialogOpen}
        closeModal={() => {
          setState({
            ...state,
            isResendVerificationEmailDialogOpen: false,
          });
        }}
      ></ResendVerificationEmailDialog>

      <ResetPasswordDialog
        token={passwordResetToken!}
        email={passwordResetEmail!}
        isOpen={state.isResetPasswordDialogOpen}
        closeModal={() => {
          setState({
            ...state,
            isResetPasswordDialogOpen: false,
          });
          setSearchParams({});
        }}
      ></ResetPasswordDialog>

      {state.loginSuccessful && <Navigate to="/home" replace={true} />}
    </>
  );
};

export default LoginPage;
